// ------------------
// Comments
// ------------------
.comment-wrapper {
  h2.title {
    background: $gray-dark;
    display: inline-block;
    border-radius: 3px;
    padding: 3px 10px;
    color: #FFF;
    font-weight: 300;
  }
  label,
  .grippie {
    display: none;
  }
  textarea {
    height: 70px;
    margin-bottom: 1rem;
    transition: all .2s ease-in-out;
  }
  form.active textarea {
    height: 120px;
  }
}

.comment-user-info {
  margin: 0 0 10px;
  padding: 0 0 0 10px;
  border-left: 3px solid $theme-gray-light;
  >div {
    display: inline-block;
    vertical-align: middle
  }
}

.comment-user-name {
  color: $theme-primary;
  font-weight: bold;
}

.comment-date {
  color: $theme-gray-light;
  font-size: 12px;
}

// Comment Form
.comment-form-wrapper {
  display: flex;
  padding: 1rem 0;
  margin: 0 0 2.5rem 0;
  border-radius: 3px;
}

.comment-user-picture-wrapper {
  width: 70px;
  margin-right: 1rem;
  img {
    @extend .img-fluid;
    border-radius: 3px;
  }
}

.comment-text-input-wrapper {
  flex: 1;
  .form-type-textarea {
    margin: 0;
  }
}

// ----------------
// COMMENTS
// ----------------
.comment-content-wrapper {
  padding-left: 15px;
  font-size: 1rem;
}

.comment {
  display: flex;
  margin-bottom: 15px;
}

.user-picture {
  flex: 0 0 48px;
  overflow: hidden;
  border-radius: 3px;
  img {
    @extend .img-fluid;
    border-radius: 3px;
  }
}

// Replays (indented comments)
.indented .comment {
  margin-left: 62px;
  .user-picture {
    flex: 0 0 36px;
  }
}

.indented>.indented {
  margin-left: 51px;
}

// Links: Reply, Edit, Delete, Permalinl...
.comment-links-wrapper {
  display: flex;
  margin-top: -10px;
  padding: 5px 0;
  font-size: .8rem;
  a {
    transition: all .2s ease-in-out;
    color: transparentize($theme-gray, .6);
    .comment:hover & {
      color: transparentize($theme-gray-dark, .5);
    }
  }
  .links.inline {
    @extend .list-unstyled;
    margin-right: 10px;
    text-transform: capitalize;
    li {
      display: inline-block;
      margin: 0 5px 0 0;
    }
  }
}
