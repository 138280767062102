// ---------------------
// Back to top button
// ---------------------

.baack-to-top-button {
  background: $gray-dark;
  display: block;
  width: 50px;
  height: 50px;
  box-shadow: 0 0 10px 0 transparentize($theme-gray-dark, .5);
  border-radius: 50px;
  transition: all .4s, color .5s, bottom .7s, ease-in-out;
  opacity: .3;
  color: $primary-light-text-color;
  font-size: 2rem;
  line-height: 48px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  position: fixed;
  right: 2rem;
  bottom: -200px;
  z-index: 800;
    &.active {
      bottom: 2rem;
    }
    &:hover {
      background: $theme-gray-dark;
      opacity: 1;
    }
}
