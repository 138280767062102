// ------------------------
// Full Pager
// ------------------------

.item-list ul.pager {
 display: block;
 margin: 3rem 0 0 0;
 padding: 2rem 0 0 0;
 border-top: 2px solid $theme-gray-lightest;
 text-align: center;
  li {
    display: inline-block;
    margin: .5rem 0;
    padding: 0;
      a, &.pager-current {
        color: $theme-gray;
        padding: 0 5px;
        @include media-breakpoint-up(md) {
          padding: 5px 15px;
          border: 1px solid $theme-gray-lighter;
        }
      }
      a {
        display: block;
        transition: background .2s ease-in-out;
        text-decoration: none;
          &:hover {
            background: $theme-gray-lightest;
          }
      }
      &.pager-current {
        background: $theme-gray-light;
        color: $primary-light-text-color;
      }
  }
}


// Load More Pager
// -------------------
.item-list .pager-load-more li a {
  padding: .8rem 3rem!important;
  font-size: $font-size-lg;
}
