// --------------------
// Footer
// --------------------
.region-footer {
  background: $theme-gray-lightest;
  margin-top: 2rem;
  padding: 5rem 0;
}

.category-list ul {
  font-size: 1.125rem;
}

// --------------------
// Tag List
// --------------------
.tag-list ul {
  text-align: left;
  li {
    display: inline-block;
    margin: 0 0 5px;
    a {
      display: block;
      background: transparentize($theme-gray-dark, .8);
      padding: 2px 10px;
      border-radius: 2px;
      color: $theme-gray-dark;
      text-decoration: none;
      transition: all .2s ease-in-out;
      &:hover {
        background: transparentize($theme-gray-dark, .5);
        color: $primary-light-text-color;
      }
    }
  }
}

// --------------------
// Footer Bar
// --------------------
.footer-bar {
  font-size: $font-size-sm;
}

.footer-bar-menu {
  li {
    display: inline-block;
    margin-left: .5rem;
    a {
      color: $theme-gray;
    }
  }
}
