// ------------------------
// Search Block
// ------------------------
.block-search {
  .element-invisible {
    display: none;
  }
  .container-inline {
    background: $theme-gray-lightest;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
    border: 1px solid $theme-gray-lightest;
  }
  .form-item {
    flex: 1;
    margin: 0;
  }
  .form-actions {
    padding-top: 0;
  }
  .form-action {
    text-align: right;
  }
  input[type='text'],
  input[type='search'] {
    background: transparent;
    width: 100%;
    height: 60px;
    margin: 0;
    padding-left: 1rem;
    border: none;
    color: $theme-gray-dark;
    font-size: 1.4rem;
  }
  input[type='submit'] {
    background: $theme-secondary;
    margin-top: 10px;
    margin-right: 0;
    border-color: $theme-secondary;
    color: $primary-light-text-color;
    font-weight: bold;
  }
}

// ------------------------
// Basic Search form
// ------------------------
.basic-search-wrapper,
.pane-search-form .container-inline {
  background: $theme-gray-lightest;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 60px;
  margin-bottom: 1rem;
  padding: 0 15px 0 0;
  border: 1px solid $theme-gray-lighter;
  .region-footer & {
    background: lighten($theme-gray-lightest, 2%);
  }
  .element-invisible {
    display: none;
  }
  .form-item {
    flex: 1;
    margin: 0;
  }
  .form-actions {
    margin-bottom: 0;
    padding-top: 0;
    text-align: right;
  }
  input[type='text'],
  input[type='search'] {
    background: transparent;
    width: 100%;
    height: 60px;
    padding-left: 15px;
    border: none;
    color: $primary-dark-text-color;
    font-size: 1.4rem;
  }
  input[type='submit'] {
    background: $theme-secondary;
    width: auto;
    margin-right: 0;
    border-color: $theme-secondary;
    color: $primary-light-text-color;
    font-weight: bold;
  }
}

.pane-block.pane-search-form .container-inline {
  input[type='text'],
  input[type='search'] {
    font-size: 1rem;
  }
}

// ------------------------
// Advanced search form
// ------------------------
.search-advanced {
  input[type='text'] {
    width: 100%;
    height: 46px;
  }
  .action {
    margin-top: 2rem;
  }
}

// ------------------------
// Search results
// ------------------------
.search-results {
  margin: 0;
  padding: 0;
  .search-result {
    background: transparent;
    transition: background .2s ease-in-out;
    &:hover {
      background: $theme-gray-lightest;
    }
  }
  h3.title {
    a {
      color: $theme-gray-dark;
    }
  }
  .search-snippet {
    margin-bottom: .2rem;
    color: $secondary-dark-text-color;
  }
  .search-info {
    margin: 0;
    color: $theme-gray-light;
    font-size: .8rem;
  }
}
