$status-icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowMDkyNjVBNkJBMkIxMUU1OEZFOUIzRTcwQjNEQURGQyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowMDkyNjVBN0JBMkIxMUU1OEZFOUIzRTcwQjNEQURGQyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAwOTI2NUE0QkEyQjExRTU4RkU5QjNFNzBCM0RBREZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAwOTI2NUE1QkEyQjExRTU4RkU5QjNFNzBCM0RBREZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+/Hg1VgAAAKhJREFUeNpi/P//PwMJQACIC4D4ARAvYABpJgHv/48ABkwk2Aqy0QFFhEgbBYD4PZKt68HeJVLzeiSNIEMUiNXs8B8VFMDkiHHufSSN+5HlGaBOgPmnAU1zP5qtBuia0Z3lAJU0QBNHN5gB5rTzWAIEWew+Nm8xINnyHs0AbK7BqhmEE/5jB/24AhRdYD6axvdQbxGlmQHNrwH4opIRS65CzTl4AECAAQDHpmBWzkZgNwAAAABJRU5ErkJggg==' !default;
$warning-icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAUCAYAAAC07qxWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowMDhCNkU4NEJBMkIxMUU1OEZFOUIzRTcwQjNEQURGQyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowMDhCNkU4NUJBMkIxMUU1OEZFOUIzRTcwQjNEQURGQyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAwODhFNUY1QkEyQjExRTU4RkU5QjNFNzBCM0RBREZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAwODhFNUY2QkEyQjExRTU4RkU5QjNFNzBCM0RBREZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+o6ufbAAAAMFJREFUeNqMUoERwiAMRM8BGIENZARGcISO4Ahu0BFkA90AR3AD3aBuEAMmNRfCXf/u2wLfBP5xAOAMTvDHCxksUYAel73rEYw5Zwk/xjjvajsDJ2Sk74x8j4SbWm8WTsiCBOJvC8KWRJ5p1PlW0SNnqhKGvVF9pT8LUaN1rI/I5Q1hkULJLjpek6dOxs7ulj3JiO7Jg4NYOCrhg97NRxnhQlY5kTEHkPkQEca4Ib1MRWNBnrU9nhZYMNNNX637CjAAa5ZUGhpB9roAAAAASUVORK5CYII=';
$error-icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowMDhGMjY3OUJBMkIxMUU1OEZFOUIzRTcwQjNEQURGQyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowMDhGMjY3QUJBMkIxMUU1OEZFOUIzRTcwQjNEQURGQyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAwOEI2RThFQkEyQjExRTU4RkU5QjNFNzBCM0RBREZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAwOEYyNjc4QkEyQjExRTU4RkU5QjNFNzBCM0RBREZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+0nmyJwAAALNJREFUeNqsVMENgzAMBNQBMopHSDdik4zAKGWDsEnYwHWQKxnLRqD6pHtwnC8J2BkRcXAAxExM/LwTV+JmunuQYiZW9FHZc6rTIQXvo3hBCz5H0UHZ2H4zCptx7CyDqgpJRFBhjbVk+I8gMFYFXuAXZmkS0F/MzhFk4VVIx/wSfSLRtQ/xLfoGWDP90xCEiTtWYxe7AebGmu2P/Nhhvz+0IZ/OmTsioUP71zUyRl1sXwEGADe0ZW8lIktJAAAAAElFTkSuQmCC';

// Message
// ------------------------
.messages {
  padding: 10px 10px 10px 62px;
  border: 1px solid $theme-gray;
  position: relative;
  &:before {
    content: "";
    background-color: $theme-gray;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 50px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
  }
}

// Status Message
// ------------------------
.messages.status {
  border-color: $theme-success;
  a {
    color: $theme-success;
  }
  &:before {
    background-color: $theme-success;
    background-image: url($status-icon);
  }
}

// Warning Message
// ------------------------
.messages.warning {
  border-color: $theme-warning;
  a {
    color: $theme-warning;
  }
  &:before {
    background-color: $theme-warning;
    background-image: url($warning-icon);
  }
}

// Error Message
// ------------------------
.messages.error {
  border-color: $theme-danger;
  a {
    color: $theme-danger;
  }
  &:before {
    background-color: $theme-danger;
    background-image: url($error-icon);
  }
}
